import * as React from "react"
import { Link } from "gatsby";
import { Builder } from "@builder.io/react";

import * as styles from "./footer.module.css";

import brandLogo from "../../assets/logo/C3ChurchGlobalLogoBlack.png"

function Footer() {
    return (
        <>
            <div className={styles.footer}>
                <Link to="/" className={styles.brandName}>
                    <img alt="C3 Global Church" src={brandLogo} />
                </Link>

                <div className={styles.bottomLinks}>
                    <div className={styles.linkGroup}>
                        <Link className={styles.linkGroupTitle}>ABOUT</Link>
                        <ul className={styles.subLinks}>
                            <li>C3 Global</li>
                            <li>Ps Phil & Chris Pringle</li>
                            <li>What We Believe</li>
                        </ul>
                    </div>

                    <div className={styles.linkGroup}>
                        <Link className={styles.linkGroupTitle}>BLOG</Link>
                    </div>

                    <div className={styles.linkGroup}>
                        <Link className={styles.linkGroupTitle}>REGIONS</Link>
                        <ul className={styles.subLinks}>
                            <li>Americas</li>
                            <li>Australia</li>
                            <li>Canada</li>
                            <li>East Asia</li>
                            <li>East Africa</li>
                            <li>Europe</li>
                            <li>Pacific</li>
                            <li>Mena</li>
                            <li>South Asia</li>
                            <li>South East Asia</li>
                            <li>Southern Africa</li>
                            <li>West Africa</li>
                        </ul>
                    </div>

                    <div className={styles.linkGroup}>
                        <Link className={styles.linkGroupTitle}>EVENTS</Link>
                    </div>

                    <div className={styles.linkGroup}>
                        <Link className={styles.linkGroupTitle}>XPRESS</Link>
                        <ul className={styles.subLinks}>
                            <li>Courses</li>
                            <li>Manuels</li>
                        </ul>
                    </div>

                    <div className={styles.linkGroup}>
                        <Link className={styles.linkGroupTitle}>CONTACT</Link>
                    </div>

                    <div className={styles.linkGroup}>
                        <Link className={styles.linkGroupTitle}>LOGIN</Link>
                        <ul className={styles.subLinks}>
                            <li>Resource Hub</li>
                            <li>Database Login</li>
                            <li>Regional Office</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;

Builder.registerComponent(Footer, {
   name: "Footer",
   inputs: [{name: 'text', type: 'string'}]
});