import * as React from "react"
import { Link } from "gatsby";
import { Builder } from "@builder.io/react";

import logo from "../../assets/logo/c3churchgloballogoblackinline.png";
import profileIcon from "../../assets/icon/profile.svg";
import searchIcon from "../../assets/icon/search.svg";

import * as styles from "./header.module.css";

function HeaderNavigation() {
    return (
        <>
            <div className={styles.topSection}>NEW C3 MUSIC ALBUM IS OUT NOW - DOWNLOAD TODAY!
                <i className={styles.iconHandPray}></i>
            </div>
            <div className={styles.headerNavigation}>
                 <div className={styles.brandName}>
                    <Link to="/">
                       <img alt="C3 Church Global" src={logo}/>
                    </Link>
                 </div>

                 <div className={styles.menuGroup}>
                    <Link to="/about">ABOUT</Link>
                    <Link to="/regions">REGIONS</Link>
                    <Link to="/blog">BLOG</Link>
                    <Link to="/events">EVENTS</Link>
                    <Link to="/xpress">XPRESS</Link>
                    <Link to="/contact">CONTACT</Link>
                    <Link className={styles.profileIconContainer} to="/profile">
                        <img width="100%" alt="profile" src={profileIcon} />
                    </Link>
                    <div>
                        <div className={styles.searchIconContainer}>
                            <img width="100%" alt="Search" src={searchIcon} />
                        </div>
                    </div>
                 </div>
            </div>
            <div className={styles.subMenu}>
                <div className={styles.regionsSubMenu}>
                    <ul>
                        <li><Link to="/">AMERICAS</Link></li>
                        <li><Link to="/">AUSTRALIA</Link></li>
                        <li><Link to="/">CANADA</Link></li>
                        <li><Link to="/">EAST ASIA</Link></li>
                        <li><Link to="/">EAST AFRICA</Link></li>
                        <li><Link to="/">EUROPE</Link></li>
                    </ul>
                    <ul>
                        <li><Link to="/">PACIFIC</Link></li>
                        <li><Link to="/">MENA</Link></li>
                        <li><Link to="/">SOUTH ASIA</Link></li>
                        <li><Link to="/">SOUTH EAST ASIA</Link></li>
                        <li><Link to="/">SOUTHERN AFRICA</Link></li>
                        <li><Link to="/">WEST AFRICA</Link></li>
                    </ul>
                 </div>
             </div>
        </>
    );
}

export default HeaderNavigation;

Builder.registerComponent(HeaderNavigation, {
    name: "Heading",
    inputs: [{name: 'text', type: 'string'}]
});
