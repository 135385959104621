import * as React from "react"
import { Link } from "gatsby";
import { Builder } from "@builder.io/react";

import * as styles from "./socmed.module.css";

import instagramIcon from "../../assets/icon/instagram.svg"
import facebookIcon from "../../assets/icon/facebook.svg"
import pinterestIcon from "../../assets/icon/pinterest.svg"
import twitterIcon from "../../assets/icon/twitter.svg"

function SocMed() {
    return (
        <>
            <div className={styles.socmedList}>
                <Link to="/" className={styles.socmedItem}>
                    <img alt="Instagram" src={instagramIcon} />
                </Link>
                <Link to="/" className={`${styles.socmedItem} ${styles.facebook}`}>
                    <img alt="Facebook" src={facebookIcon} />
                </Link>
                <Link to="/" className={`${styles.socmedItem} ${styles.pinterest}`}>
                    <img alt="Pinterest" src={pinterestIcon} />
                </Link>
                <Link to="/" className={styles.socmedItem}>
                    <img alt="Twitter" src={twitterIcon} />
                </Link>
            </div>
        </>
    );
}

export default SocMed;

Builder.registerComponent(SocMed, {
    name: "Social Media",
    inputs: [{name: 'text', type: 'string'}] // this is just a sample input for builder editor. Will refactor this later
});